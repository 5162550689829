// @flow

import * as React from 'react'
import { Link } from 'react-router-dom'
import LanguageSelection from '../LanguageSelection/LanguageSelection'
import afLogo from 'images/af_logo_blue_2018.svg'
import afLogoSmall from 'images/af_logo_small.svg'
import jobskillsLogo from 'images/jobskills_logo.png'
import styled, { injectGlobal } from 'styled-components'
// const JOBSKILLS_SEARCH_URL =
//   'https://jobskills-dev.apps.tocp4.arbetsformedlingen.se/search/'

const Wrap = styled.header`
  background-color: #fff;
  padding: 8px 16px;

  @media (min-width: 801px) {
    padding-bottom: 24px;
    padding-top: 24px;
  }

  @media (min-width: 1100px) {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr 1070px 1fr;
    -ms-grid-columns: 1fr 1070px 1fr;

    > * {
      grid-column: 2;
      -ms-grid-column: 2;
    }
  }
`

const Inner = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const Logo = styled.img`
  width: 42px;

  @media (min-width: 801px) {
    width: 54px;
  }
`

const AfLogo = styled.img`
  max-width: 312px;
  vertical-align: middle;
  width: 70%;

  @media screen and (max-width: 63rem) {
    display: none;
  }
`

const AfLogoSmall = styled.img`
  @media screen and (min-width: 63rem) {
    display: none;
  }
`

const Separator = styled.div`
  height: 45px;
  border-left: 1px solid #cdd1d5;
  margin: 0 30px;

  @media screen and (max-width: 63rem) {
    margin: 0 15px;
  }
`

const Navigation = styled.nav`
  display: none;
  font-size: 1.125rem;
  margin-right: ${({ theme }) => theme.sizes.spacing};

  @media (min-width: 801px) {
    display: block;
  }
`

injectGlobal`
  html.rtl ${Navigation} {
    margin-left: 30px;
    margin-right: 0;
  }
`

const StrippedHeader = () => {
  return (
    <Wrap>
      <Inner>
        <Link to="/">
          <Logo alt="Jobskills logo" src={jobskillsLogo} />
        </Link>
        <Separator />
        <AfLogoSmall alt="Arbetsförmedlingen logo" src={afLogoSmall} />
        <AfLogo alt="Arbetsförmedlingen logo" src={afLogo} />
        <LanguageSelection dropdown />
      </Inner>
    </Wrap>
  )
}

export default StrippedHeader
