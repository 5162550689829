// @flow

import * as React from 'react'
import Chevron from 'components/Chevron/Chevron'
import languageIcon from 'images/language_icon.svg'
import styled, { injectGlobal } from 'styled-components'
import type { ValidLocale } from 'types/af.flow'

type LanguageSelectionDropdownProps = {
  userLanguages: { id: string, name: string }[],
  language: ValidLocale,
  updateLocale: (locale: string, force: boolean) => Function,
}

type LanguageSelectionDropdownState = {
  showLanguageSelection: boolean,
}

const getSelectedLanguage = (
  userLanguages: { id: string, name: string }[],
  language: ValidLocale
): { id: string, name: string } | Object =>
  userLanguages.find(lang => lang.id === language) || {}

const Overlay = styled.div`
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`

const LanguageSelectionContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @media (min-width: 801px) {
    margin-right: 80px;
  }
`

const LanguageSelectionList = styled.ul`
  background-color: ${({ theme }) => theme.nisColors.white};
  border-radius: 5px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  left: 0;
  position: absolute;
  top: calc(100% + 20px);
  min-width: 200px;
  left: 50%;
  transform: translateX(-50%);
`

const SelectedLanguage = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.nisColors.links};
  display: flex;

  > span {
    margin-left: ${({ theme }) => theme.sizes.third};
    margin-right: ${({ theme }) => theme.sizes.third};
  }
`

const LanguageSelectionItem = styled.li`
  padding: ${({ theme }) => theme.sizes.third}};

  &:hover {
    background-color: ${({ theme }) => theme.nisColors.links};
    color: ${({ theme }) => theme.nisColors.white};
  }

  &:not(:last-child) {
    border-bottom: 1px solid #c4c4c4;
  }

  &:marker {
    display: none;
  }
`

injectGlobal`
  html[data-whatinput="mouse"],
  html[data-whatinput="touch"] {
    ${SelectedLanguage}:focus,
    ${LanguageSelectionItem}:focus {
      outline: none;
    }
  }

  html.rtl ${LanguageSelectionContainer} {
    @media (min-width: 801px) {
      margin-left: 80px;
      margin-right: auto;
    }
  }
`

export class LanguageSelectionDropdown extends React.PureComponent<
  LanguageSelectionDropdownProps,
  LanguageSelectionDropdownState
> {
  state = {
    showLanguageSelection: false,
  }

  toggleLanguageSelection = () => {
    this.setState(state => ({
      showLanguageSelection: !state.showLanguageSelection,
    }))
  }

  handleKeyUp = (e: SyntheticKeyboardEvent<HTMLLIElement>) => {
    if (e.keyCode === 13) {
      this.toggleLanguageSelection()
    }
  }

  languageItemKeyUp = (
    e: SyntheticKeyboardEvent<HTMLDivElement>,
    id: string
  ) => {
    if (e.keyCode === 13) {
      this.props.updateLocale(id, true)
    }
  }

  render() {
    const { userLanguages, language, updateLocale } = this.props
    const { showLanguageSelection } = this.state
    const selectedLanguage = getSelectedLanguage(userLanguages, language)

    return (
      <LanguageSelectionContainer>
        {showLanguageSelection && (
          <Overlay onClick={this.toggleLanguageSelection} />
        )}
        <SelectedLanguage
          onClick={this.toggleLanguageSelection}
          onKeyUp={this.handleKeyUp}
          tabIndex="0"
        >
          <img src={languageIcon} />
          <span>{selectedLanguage.name}</span>
          <Chevron isOpen={showLanguageSelection} size={12} />
        </SelectedLanguage>
        {showLanguageSelection && (
          <LanguageSelectionList>
            {userLanguages
              .filter(
                lang =>
                  (lang.id !== selectedLanguage.id && lang.id === 'en') ||
                  lang.id === 'sv'
              )
              .map(lang => (
                <LanguageSelectionItem
                  key={lang.id}
                  onClick={() => updateLocale(lang.id, true)}
                  onKeyUp={e => this.languageItemKeyUp(e, lang.id)}
                  tabIndex="0"
                >
                  {lang.name}
                </LanguageSelectionItem>
              ))}
          </LanguageSelectionList>
        )}
      </LanguageSelectionContainer>
    )
  }
}

export default LanguageSelectionDropdown
