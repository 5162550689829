// @flow

import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import Header from 'components/Header/Header'
import Navigation from 'components/Navigation/Navigation'
import NisHeaderLite from './routes/Start/NisHeader/NisHeaderLite'
import StrippedHeader from 'components/Header/StrippedHeader'

export const HeadComponent = () => (
  <>
    <Header subtitle="globals.header.subtitle" title="globals.header.title" />
    <Navigation />
  </>
)

const AppHeader = () => {
  return (
    <Switch>
      <Route component={StrippedHeader} exact path="/" />
      <Route component={StrippedHeader} path="/about" />
      <Route component={NisHeaderLite} path="*" />
    </Switch>
  )
}

export default AppHeader
