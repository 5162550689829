// @flow

import * as React from 'react'
import CookieWarning from 'components/CookieWarning'
import SecondaryButtonLink from 'components/Button/SecondaryButtonLink'
import cover from 'images/jobskills_bakgrundsbild.jpg'
import coverMobile from 'images/jobskills_bakgrundsbild_mobil.jpg'
import coverAR from 'images/jobskills_bakgrundsbild_ar.jpg'
import coverMobileAR from 'images/jobskills_bakgrundsbild_mobil_ar.jpg'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { currentLocale } from 'selectors/translations'

export const startPageData = [
  {
    lang: 'ar',
    title:
      ' إن خدمة Jobskills ستغلق و تُعوض بخدمة رقمية تابعة لمكتب العمل(Arbetsförmedlingen) 2024/12/20.',
    description:
      '، فيمكنك من الآن إنشاء ملف الكفاءات على خدمتنا الرقمية الجديدة. قبل زوال خدمة Jobskills بشهر واحد، سنعلن على هذه الصفحة عن موعد إغلاق الخدمة. إذا كان لديك ملف يتضمّن كفاءاتك ',
    btnText: 'إنشاء ملف تعريف الكفاءة',
  },
  {
    lang: 'en',
    title: 'Jobskills has been shut down and replaced with the skills profile',
    description: '',
    btnText: 'Create a skills profile',
  },
  {
    lang: 'fa',
    title:
      'Jobskills بسته می شود و با یک سرویس دیجیتال جدید از خدمات استخدام عمومی (Arbetsförmedlingen) در تاریخ 20/12-2024 جایگزین می شود.',
    description:
      'اگر شما پناهجو هستید یا به تازگی به سوئد آمده‎‎اید و در جستجوی کار هستید، می‎توانید از همین حالا در سرویس دیجیتالی جدید ما یک پروفایل مهارت بسازید. ما حداقل یک ماه قبل از بسته شدن Jobskills در این صفحه زمان تعطیل شدن آن را اعلام خواهیم کرد. اگر پروفایلی در Jobskills داشته باشید حذف خواهد شد.',
    btnText: 'یک پروفایل ایجاد کنید',
  },
  {
    lang: 'ru',
    title:
      'Сервис Jobskills прекратит свою работу. На смену ему приходит новый электронный сервис Агентства по трудоустройству (Arbetsförmedlingen) 2024/12/20.',
    description:
      'Если вы соискатель убежища, или вы недавно прибыли в Швецию, и ищете работу, вы уже сейчас можете создать профиль компетенций в нашем новом электронном сервисе. Дату закрытия сервиса Jobskills мы объявим на нашем сайте по меньшей мере за месяц до закрытия. Если у вас есть профиль на Jobskills, он будет удален.',
    btnText: 'Создать профиль навыков',
  },
  {
    lang: 'so',
    title:
      'Xirfadaha shaqada waa la xirayaa waxaana lagu bedelayaa adeeg cusub oo dhijitaal ah oo ka socda Adeegga Shaqada Dadweynaha (Arbetsförmedlingen) 2024/12/20.',
    description:
      'Haddii aad tahay magangalyo doon ama aad ku cusub tahay Iswidhan oo aad shaqo raadinayso, waxa aad horeba u abuuri kartaa profile xirfad adeegeena cusub ee dhijitaalka ah. Wax aan ka dambayn hal bil ka hor inta aanay lumin xirfadaha shaqada, waxaanu boggan kaga dhawaaqi doonaa in adeeggu xidhan yahay. Haddii aad leedahay profile Jobskills, waa la tirtiri doonaa.',
    btnText: 'Abuur astaan ​​xirfadeed',
  },
  {
    lang: 'sv',
    title: 'Jobskills har stängts ned och ersatts med kompetensprofilen',
    description: '',
    btnText: 'Skapa en kompetensprofil',
  },
  {
    lang: 'ti',
    title:
      'Jobskills ይዕፀው ኣብ ኤጀንሲ መራኸቢ ስራሕ (Arbetsförmedlingen) ብዘሎ ሓድሽ ዲጂታላዊ ግልጋሎት እውን ይትካእ። 2024/12/20.',
    description:
      'ደላዪ ዕቑባ ወይከዓ ንሽወደን ሓድሽ እንተኾይኖምን ስራሕ እንዳኣለሹ እንተኾይኖምን ኣብቲ ሓድሽ ዲጂታላዊ ግልጋሎትና ናይ ክእለታት ፕሮፋይል ክፈጥሩ ይኽእሉ እዮም። Jobskills ቅድሚ ምጥፍኡ እንተንኣሰ ቅድሚ ሓደ ወርሒ እቲ ግልጋሎት መዓዝ ከም ዝዕፀው ኣብዚ ሳይት ክነፍልጥ ኢና። ኣብ Jobskills ፕሮፋይል እንተልዩዎም ክድምሰስ እዩ።',
    btnText: 'ናይ ክእለት ፕሮፋይል ምፍጣር',
  },
  {
    lang: 'ua',
    title:
      'Jobskills (Робочі навички) закривається та замінюється новим цифровим сервісом у Службі зайнятості 2024/12/20.',
    description:
      'Якщо ви шукаєте притулок або вперше приїхали до Швеції та шукаєте роботу, ви вже можете створити профіль компетентності у нашому новому цифровому сервісі. Не пізніше ніж за місяць до закриття сервісу Jobskills ми оголосимо про це на цій сторінці. Якщо у вас є профіль Jobskills, він буде видалений.',
    btnText: 'Створіть профіль навичок',
  },
]

function Start(props) {
  const { language } = props

  const isRtl = language === 'ar' || language == 'fa'

  const langData = startPageData.find(data => data.lang === language)

  return (
    <>
      <Body isRtl={isRtl}>
        <Card>
          <Title isRtl={isRtl}>{langData.title}</Title>

          <Text isRtl={isRtl}>{langData.description}</Text>

          <LinkContainer>
            <LinkButton
              isRtl={isRtl}
              href="https://arbetsformedlingen.se/for-arbetssokande/sa-hittar-du-jobbet/skapa-en-kompetensprofil"
            >
              {langData.btnText}
            </LinkButton>
          </LinkContainer>
        </Card>

        <CookieWarning />
      </Body>
    </>
  )
}

const Body = styled.main`
  background-image: url(${props =>
    props.isRtl ? coverMobile : coverMobileAR});
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #d5d5d5;
  justify-content: center;

  flex: 1;
  height: 100%;

  @media (min-width: 36rem) {
    padding: 2rem 10rem;
    display: grid;
    background-image: url(${props => (props.isRtl ? coverAR : cover)});
    background-position: right 20% center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: row;
    justify-content: flex-start;
    grid-template-columns: repeat(2, 1fr);
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 130px 16px 16px 16px;
  background: rgb(255, 255, 255);
  border-radius: 0px;
  padding: 20px;
  justify-content: center;

  @media (min-width: 36rem) {
    background: none;
    margin: 80px 16px;
  }
`

const Title = styled.h1`
  color: rgb(51, 51, 51);
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 42px;
  padding: 0px;
  margin-bottom: 0;
  text-align: left;

  @media (min-width: 36rem) {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0px;
  }

  ::after {
    width: 0px;
    height: 0px;
    margin-bottom: 0;
    content: none;
  }
`

const Text = styled.p`
  color: rgb(51, 51, 51);
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
  margin-bottom: 0px;
  text-align: left;

  @media (min-width: 36rem) {
    color: rgb(255, 255, 255);
    font-weight: 600;
    line-height: 27px;
  }
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 36rem) {
    width: 360px;
  }
`

const LinkButton = styled.a.attrs({
  className: 'btn btn-primary btn-full',
})`
  /* This overwrites the global styling of a */
  color: #fff !important;
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0px;
  line-height: 27px;
`

const SignInLink = styled(SecondaryButtonLink)`
  color: #000 !important;
  border: 1px solid rgb(0, 0, 90);
  border-radius: 6px;
`

export const mapStateToProps = state => ({
  language: currentLocale(state),
})

export default connect<any, any, any, any, any, any, any>(mapStateToProps)(
  Start
)
